<template>
  <div id="customers-VIP-list">
    <v-card>
      <v-card-title>定期便会員一覧</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="mbr_sei_kana"
            placeholder="セイ"
            outlined
            dense
            hide-details
            @change="changeSeiKana"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="mbr_mei_kana"
            placeholder="メイ"
            outlined
            dense
            hide-details
            @change="changeMeiKana"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="mbr_status"
            :items="memberStatuses"
            placeholder="会員状態"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateStartMenu"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :nudge-right="40"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="入会日From"
                dense
                hide-details
                readonly
                outlined
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="入会日To"
                dense
                outlined
                hide-details
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="subscribedmemberList"
        sort-by
        class="elevation-1"

        :search="search"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div>
            <span>{{ item.mbr_sei }} {{ item.mbr_mei }}</span>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
            <delete-form
              :icon="icons.mdiDelete"
              class="py-2 pl-1"
            ></delete-form>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiCalendar,
  mdiMagnify,
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiExportVariant,
} from '@mdi/js'
import {
  mapState, mapMutations, mapActions,
} from 'vuex'
import DeleteForm from '@/views/bimi/product/item/ProductsDelete.vue'

export default {
  components: {
    DeleteForm,
  },
  data: () => ({
    mbr_sei_kana: '',
    mbr_mei_kana: '',
    startDate: '',
    endDate: '',
    dateEndMenu: false,
    dateStartMenu: false,
    search: '',
    mbr_status: '',
    memberStatuses: [{
      text: '仮登録', value: 0,
    }, {
      text: 'メール認証済', value: 1,
    }, {
      text: '仮会員', value: 2,
    }, {
      text: 'セット会員', value: 3,
    }, {
      text: '定期便会員', value: 4,
    }, {
      text: '定期便中止', value: 6,
    }, {
      text: '退会', value: 9,
    }],
    icons: {
      mdiCalendar,
      mdiPencil,
      mdiMagnify,
      mdiDelete,
      mdiPlus,
      mdiExportVariant,
    },
    headers: [
      {
        text: 'ID',
        value: 'mbr_id',
        align: 'left',
        width: '10%',
        fixed: true,
      },
      {
        text: '姓',
        value: 'mbr_sei',
        align: ' d-none',
      },
      {
        text: '名',
        value: 'mbr_mei',
        align: ' d-none',
      },
      {
        text: 'セイ',
        value: 'mbr_sei_kana',
        align: ' d-none',
      },
      {
        text: 'メイ',
        value: 'mbr_mei_kana',
        align: ' d-none',
      },
      {
        text: '会員氏名',
        align: 'left',
        sortable: true,
        value: 'name',
        width: '26%',
        fixed: true,
      },
      {
        text: '入会日',
        value: 'mbr_subs_fpd',
        width: '26%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '会員状態',
        value: 'ms_desc',
        width: '26%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '6%',
        align: 'center',
        fixed: true,
      },
    ],
  }),
  computed: {
    ...mapState('subscribedMemberStore', ['subscribedmemberList']),
  },
  watch: {
    mbr_status(newValue) {
      this.changeRouteQuery('mbr_status', newValue)
      this.loadData()
    },
    startDate(newValue) {
      this.changeRouteQuery('startDate', newValue)
      this.loadData()
    },
    endDate(newValue) {
      this.changeRouteQuery('endDate', newValue)
      this.loadData()
    },
  },
  created() {
    this.loadQuery()
    this.changeRouteQuery()
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('subscribedMemberStore', ['loadSubscribedmembers']),
    changeSeiKana() {
      this.changeRouteQuery('mbr_sei_kana', this.mbr_sei_kana)
      this.loadData()
    },
    changeMeiKana() {
      this.changeRouteQuery('mbr_mei_kana', this.mbr_mei_kana)
      this.loadData()
    },
    loadData() {
      this.setOverlayStatus(true)
      this.loadSubscribedmembers(this.$route.query).finally(() => {
        this.setOverlayStatus(false)
        console.log('loadSettlementList', this.subscribedmemberList)
      })
    },

    editItem(item) {
      this.$router.push({
        name: 'customer-edit',
        params: {
          id: item.crushed_id, query: this.$route.query,
        },
      })
    },

    loadQuery() {
      this.mbr_sei_kana = this.$route.query.mbr_sei_kana ?? ''
      this.mbr_mei_kana = this.$route.query.mbr_mei_kana ?? ''
      // eslint-disable-next-line radix
      this.mbr_status = this.$route.query.mbr_status ? parseInt(this.$route.query.mbr_status) : ''
      this.startDate = this.$route.query.startDate ?? ''
      this.endDate = this.$route.query.endDate ?? ''
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val || val === 0) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path, query,
      })
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
